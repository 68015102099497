import React, { useState } from 'react';
import AddDocumentModal from '../modals/AddDocumentModal'; 
import documents from "../../assets/images/documents.png"
import DocumentChatModal from '../modals/DocumentChatModal';

const DocumentosCaso = ({ documentos, handleDownload, handleDelete, handleUpload }) => {
  const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const openAddDocumentModal = () => {
    setIsAddDocumentModalOpen(true);
  };

  const closeAddDocumentModal = () => {
    setIsAddDocumentModalOpen(false);
  };

  const openChatModal = (documentId) => {
    setSelectedDocumentId(documentId);
    setIsModalOpen(true);
  };

  const closeChatModal = () => {
    setIsModalOpen(false);
    setSelectedDocumentId(null);
  };

  return (
    <>
      <div className="bg-gray-800 text-white p-4 flex flex-col sm:flex-row mt-2 rounded-md justify-between items-center">
        <h1 className="p-2 text-lg sm:text-xl md:text-2xl mb-2 sm:mb-0">Documentos</h1>
        <button 
          className="hover:bg-gray-100 p-2 rounded-sm hover:text-gray-900 transition duration-300 text-sm sm:text-base"
          onClick={openAddDocumentModal}
        >
          Agregar Documento
        </button>
      </div>
      <div className="bg-gray-100 p-4 mt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {documentos.map((documento, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center space-y-2">
              <p className="text-center"><span className="font-bold">Título:</span> {documento.nombreDocumento}</p>
              <img src={documents} alt="Document" className="w-20 sm:w-24 md:w-28 lg:w-32 mt-4"/>
              <p className="text-sm"><span className="font-bold">Fecha:</span> {documento.fechaSubida}</p>
              <button 
                onClick={() => handleDownload(documento.idDocumento)} 
                className="w-full drop-shadow-md px-2 py-1 text-xs sm:text-sm hover:bg-gray-100 border-b border-gray-300 hover:text-white hover:bg-gray-800 text-black font-medium rounded transition duration-300"
              >
                Descargar Documento
              </button>
              <button 
                onClick={() => handleDelete(documento.idDocumento)} 
                className="w-full drop-shadow-md px-2 py-1 text-xs sm:text-sm hover:bg-gray-100 border-b border-gray-300 hover:text-white hover:bg-gray-800 text-black font-medium rounded transition duration-300"
              >
                Eliminar Documento
              </button>
              <button
                onClick={() => openChatModal(documento.idDocumento)}
                className="w-full drop-shadow-md px-2 py-1 text-xs sm:text-sm hover:bg-gray-100 border-b border-gray-300 hover:text-white hover:bg-gray-800 text-black font-medium rounded transition duration-300"
              >
                Chatear con Documento
              </button>
            </div>
          ))}
        </div>
      </div>
      <AddDocumentModal
        isOpen={isAddDocumentModalOpen}
        onRequestClose={closeAddDocumentModal}
        handleUpload={handleUpload}
      />
      <DocumentChatModal
        isOpen={isModalOpen}
        onClose={closeChatModal}
        documentId={selectedDocumentId}
      />
    </>
  );
};

export default DocumentosCaso;
