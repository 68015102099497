import React, { useState, useEffect } from 'react';
import { Loader, Search, Filter } from 'lucide-react';

const Citas = () => {
  const [citas, setCitas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCitas = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/citas-agendadas');
        const data = await response.json();
        if (data.Issuccessful) {
          setCitas(data.Details);
        } else {
          setError(data.Description);
        }
      } catch (error) {
        setError('Error al cargar las citas');
      } finally {
        setIsLoading(false);
      }
    };
    fetchCitas();
  }, []);

  const filteredCitas = citas.filter(cita => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (cita.nombre?.toLowerCase() || '').includes(searchTermLower) ||
      (cita.apellido?.toLowerCase() || '').includes(searchTermLower) ||
      (cita.motivo?.toLowerCase() || '').includes(searchTermLower) ||
      (cita.horaInicio?.toLowerCase() || '').includes(searchTermLower)
    );
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-gray-700" size={36} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-semibold mb-10 mt-6 text-gray-800">Citas Agendadas</h1>
      
      <div className="mb-4 flex items-center justify-between">
        <div className="flex-grow mr-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Buscar por nombre, apellido, motivo o hora..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <button className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300 flex items-center">
          <Filter size={18} className="mr-2" />
          Filtrar
        </button>
      </div>

      <div className="overflow-x-auto bg-white shadow-md mt-10 rounded-lg">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apellido</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hora</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Motivo</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acción</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredCitas.map((cita, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{cita.nombre || 'N/A'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{cita.apellido || 'N/A'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cita.horaInicio || 'N/A'}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{cita.motivo || 'N/A'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{cita.accion || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {filteredCitas.length === 0 && (
        <p className="text-center text-gray-500 mt-4">No se encontraron citas agendadas.</p>
      )}
    </div>
  );
};

export default Citas;