import React, { useState, useEffect } from 'react';
import { Loader, Search } from 'lucide-react';

const ActividadUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/accesos-usuarios');
        const data = await response.json();
        if (data.Issuccessful) {
          setUsuarios(data.Details);
        } else {
          setError(data.Description);
        }
      } catch (error) {
        setError('Error al cargar los datos de usuarios');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsuarios();
  }, []);

  const filteredUsuarios = usuarios.filter(usuario => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (usuario.idUsuario?.toString().toLowerCase() || '').includes(searchTermLower) ||
      (new Date(usuario.fechaHoraAcceso).toLocaleDateString() || '').includes(searchTermLower) ||
      (new Date(usuario.fechaHoraAcceso).toLocaleTimeString() || '').includes(searchTermLower)
    );
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-gray-700" size={36} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-semibold mb-4 text-gray-800">Actividad de Usuarios</h1>
      
      <div className="mb-4 flex items-center">
        <Search className="text-gray-400 mr-2" size={20} />
        <input
          type="text"
          placeholder="Buscar por usuario, fecha o hora..."
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hora</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usuario</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredUsuarios.map((usuario, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {usuario.fechaHoraAcceso
                    ? new Date(usuario.fechaHoraAcceso).toLocaleDateString()
                    : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {usuario.fechaHoraAcceso
                    ? new Date(usuario.fechaHoraAcceso).toLocaleTimeString()
                    : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {usuario.idUsuario || 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {filteredUsuarios.length === 0 && (
        <p className="text-center text-gray-500 mt-4">No se encontraron registros de actividad.</p>
      )}
    </div>
  );
};

export default ActividadUsuarios;