import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTrash, FaEdit } from 'react-icons/fa';

// Función para formatear los precios en colones costarricenses
const formatPrice = (price) => {
  return `₡${price.toLocaleString('es-CR')}`;
};
var Token = localStorage.getItem('token');

const API_URL = 'https://marianachaconramirez.com';


const DataTable = () => {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedServices, setSelectedServices] = useState([]);
  const servicesPerPage = 5;
  const [token, setToken] = useState(Token); // Token guardado en el estado

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.post(`${API_URL}/obtenerServicios`, {
          token: token
        });
        
        if (response.data.IsSuccessful) {
          const servicesData = response.data.Details.map(service => ({
            id: service.idServicio,
            name: service.nombreServicio,
            description: service.descripcion,
            basePrice: service.precioBase
          }));
          setServices(servicesData);
        } else {
          console.error('Error fetching services:', response.data.Description);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [token]);

  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredServices.length / servicesPerPage);
  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);

  const handleEdit = (id) => {
    alert(`Editar servicio con ID: ${id}`);
  };

  const handleDelete = (id) => {
    const updatedServices = services.filter(service => service.id !== id);
    setServices(updatedServices);
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleSelect = (service) => {
    setSelectedServices((prevSelected) => {
      const isSelected = prevSelected.some(s => s.id === service.id);
      if (isSelected) {
        return prevSelected.filter(s => s.id !== service.id);
      } else {
        return [...prevSelected, service];
      }
    });
  };

  const totalSelectedPrice = selectedServices.reduce((acc, service) => acc + service.basePrice, 0);

  return (
    <div className="p-4 max-w-6xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-semibold mb-6 text-gray-900">Servicios</h1>
      
      <div className="flex flex-col md:flex-row md:space-x-6">
        <div className="w-full md:w-2/3">
          <input
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-900"
          />
          
          {filteredServices.length === 0 ? (
            <p className="text-center text-gray-500">No se encontraron resultados</p>
          ) : (
            <>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Seleccionar</th>
                      <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre de Servicio</th>
                      <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descripción</th>
                      <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Precio Base</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentServices.map(service => (
                      <tr key={service.id} className="hover:bg-gray-50">
                        <td className="p-3">
                          <input
                            type="checkbox"
                            checked={selectedServices.some(s => s.id === service.id)}
                            onChange={() => handleSelect(service)}
                            className="form-checkbox h-5 w-5 text-gray-900"
                          />
                        </td>
                        <td className="p-3 text-sm text-gray-900">{service.name}</td>
                        <td className="p-3 text-sm text-gray-500">{service.description}</td>
                        <td className="p-3 text-sm text-gray-900">{formatPrice(service.basePrice)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-center mt-6">
                <nav>
                  <ul className="flex space-x-1">
                    {[...Array(totalPages).keys()].map(number => (
                      <li key={number + 1}>
                        <button
                          className={`px-4 py-2 border rounded-md ${number + 1 === currentPage ? 'bg-gray-900 text-white' : 'bg-white text-gray-900 hover:bg-gray-100'}`}
                          onClick={() => handlePageChange(number + 1)}
                        >
                          {number + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </>
          )}
        </div>

        <div className="w-full md:w-1/3 mt-6 md:mt-0">
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h4 className="text-xl font-semibold text-gray-900 mb-4">Servicios Seleccionados</h4>
            {selectedServices.length > 0 ? (
              <>
                <ul className="space-y-2">
                  {selectedServices.map(service => (
                    <li key={service.id} className="flex justify-between items-center text-sm">
                      <span className="text-gray-700">{service.name}</span>
                      <span className="font-medium text-gray-900">{formatPrice(service.basePrice)}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-4 pt-4 border-t border-gray-200">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-semibold text-gray-900">Total:</span>
                    <span className="text-xl font-bold text-gray-900">{formatPrice(totalSelectedPrice)}</span>
                  </div>
                </div>
              </>
            ) : (
              <p className="text-gray-500 text-center">No hay servicios seleccionados</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;