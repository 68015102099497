import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { startOfMonth, endOfMonth, isWithinInterval, parseISO } from 'date-fns';

const HomeAbogado = () => {
  const [allData, setAllData] = useState([]);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF0000'];

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch('https://marianachaconramirez.com/api/usuarios/grafico');
        const data = await response.json();
        setAllData(data.map(item => ({
          name: item.Cliente,
          motivo: item.motivo,
          fecha: item.Fecha,
        })));
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const today = new Date();
  const startMonth = startOfMonth(today);
  const endMonth = endOfMonth(today);

  // Filtrar los datos del mes actual
  const currentMonthData = allData.filter(caseItem => {
    const caseDate = parseISO(caseItem.fecha);
    return isWithinInterval(caseDate, { start: startMonth, end: endMonth });
  });

  // Contar los casos por motivo
  const dataByMotivo = currentMonthData.reduce((acc, caseItem) => {
    const existingMotivo = acc.find(item => item.motivo === caseItem.motivo);
    if (existingMotivo) {
      existingMotivo.cases += 1;
    } else {
      acc.push({ motivo: caseItem.motivo, cases: 1 });
    }
    return acc;
  }, []);

  // Ordenar por la cantidad de casos y obtener los 5 más comunes
  const top5Data = dataByMotivo.sort((a, b) => b.cases - a.cases).slice(0, 5);

  return (
    <div className='font-inter text-sm'>
      <h1 className='text-2xl mb-5 text-center font-bold'>Cantidad de casos más comunes en el mes actual</h1>
      <div className='bg-white border border-gray-200 p-6 rounded-lg shadow-lg'>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={top5Data}
              dataKey="cases"
              nameKey="motivo"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              label={false}
            >
              {top5Data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend verticalAlign="bottom" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HomeAbogado;