import React, { useState, useEffect } from 'react';

const DocumentChatModal = ({ isOpen, onClose, documentId }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');
  useEffect(() => {
    console.log('Token in DocumentChatModal:', token);
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Submitting question with token:', token);
    try {
      const response = await fetch('https://marianachaconramirez.com/pregunta-documento', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          idDocumento: documentId,
          pregunta: question
        })
      });

      const data = await response.json();
      console.log('Received response:', data);

      if (data.IsSuccessful) {
        setAnswer(data.Details);
      } else {
        throw new Error(data.Description);
      }
    } catch (error) {
      console.error('Error al preguntar sobre el documento:', error);
      setAnswer('Lo siento, hubo un error al procesar tu pregunta: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-gray-100 w-full max-w-md m-auto flex-col flex rounded-lg">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Chat con Documento</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="question" className="block text-sm font-medium text-gray-700">
              Tu pregunta:
            </label>
            <input
              type="text"
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Escribe tu pregunta aquí"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full px-4 py-2 text-white bg-gray-800 rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            {isLoading ? 'Procesando...' : 'Enviar Pregunta'}
          </button>
        </form>
        {answer && (
          <div className="mt-4">
            <h3 className="text-md font-medium text-gray-900">Respuesta:</h3>
            <p className="mt-2 text-sm text-gray-500">{answer}</p>
          </div>
        )}
        <button
          onClick={onClose}
          className="mt-4 w-full px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default DocumentChatModal;