import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'lucide-react';

const NavbarInformes = ({ activeLink, setActiveLink }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const sections = useMemo(() => [
    'Casos y numeros de seguridad',
    'Citas',
    'Modificaciones',
    'Actividad de Usuarios'
  ], []);

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    if (currentPath) {
      const formattedPath = sections.find(section =>
        section.toLowerCase().replace(/\s/g, '-') === currentPath
      );
      if (formattedPath) {
        setActiveLink(formattedPath);
      }
    }
  }, [location, sections, setActiveLink]);

  const handleClick = (e, section) => {
    e.preventDefault();
    setActiveLink(section);
    setIsMenuOpen(false);
  };

  return (
    <section className='font-inter bg-gray-100 border-black w-full'>
      <div className="md:hidden flex justify-end p-4">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle menu">
          <Menu size={24} />
        </button>
      </div>
      <nav className={`${isMenuOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row justify-evenly w-full mx-auto h-full`}>
        {sections.map((section, index) => (
          <div
            key={index}
            className={`flex justify-center w-full border-customBorder ${
              activeLink === section ? 'text-gray underline font-bold' : 'text-black'
            }`}
          >
            <a
              className="flex items-center justify-center w-full h-full md:border-r-2 p-4 border-customLightGray"
              href={`#${section.toLowerCase().replace(/\s/g, '-')}`}
              onClick={(e) => handleClick(e, section)}
            >
              {section}
            </a>
          </div>
        ))}
      </nav>
    </section>
  );
};

export default NavbarInformes;