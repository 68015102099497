import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado
import { FaUserFriends, FaCalendarAlt, FaConciergeBell, FaFileInvoice } from 'react-icons/fa'; // Biblioteca de íconos de react-icons

const AccesosDirectos = () => {
  return (
    <div className='font-inter text-sm'>
      <h1 className='text-2xl mb-5 text-center font-bold'>Accesos Directos</h1>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
        {/* Cuadro 1 */}
        <Link to="/clientes" className='bg-white border border-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center'>
          <div className='text-4xl mb-3'>
            <FaUserFriends /> {/* Ícono para Clientes */}
          </div>
          <h2 className='text-lg font-semibold text-gray-800'>Clientes</h2>
          <p className='text-gray-600'>Administra los clientes y su información.</p>
        </Link>

        {/* Cuadro 2 */}
        <Link to="/citas" className='bg-white border border-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center'>
          <div className='text-4xl mb-3'>
            <FaCalendarAlt /> {/* Ícono para Citas */}
          </div>
          <h2 className='text-lg font-semibold text-gray-800'>Citas</h2>
          <p className='text-gray-600'>Revisa tu agenda.</p>
        </Link>

        {/* Cuadro 3 */}
        <Link to="/servicios" className='bg-white border border-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center'>
          <div className='text-4xl mb-3'>
            <FaConciergeBell /> {/* Ícono para Servicios */}
          </div>
          <h2 className='text-lg font-semibold text-gray-800'>Servicios</h2>
          <p className='text-gray-600'>Administra los servicios que tus clientes pueden ver.</p>
        </Link>

        {/* Cuadro 4 */}
        <Link to="/facturas" className='bg-white border border-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center'>
          <div className='text-4xl mb-3'>
            <FaFileInvoice /> {/* Ícono para Facturas */}
          </div>
          <h2 className='text-lg font-semibold text-gray-800'>Facturas</h2>
          <p className='text-gray-600'>Facturación electrónica.</p>
        </Link>
      </div>
    </div>
  );
};

export default AccesosDirectos;
