import React, { useState } from 'react';
import documents from "../../assets/images/documents.png";
import DocumentChatModal from '../modals/DocumentChatModal';

const Documentos = ({ documentos, handleDownload, handleDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);


  const openChatModal = (documentId) => {
    setSelectedDocumentId(documentId);
    setIsModalOpen(true);
  };

  const closeChatModal = () => {
    setIsModalOpen(false);
    setSelectedDocumentId(null);
  };


  return (
    <>
      <div className="bg-gray-800 text-white p-4 flex justify-between mt-2 rounded-md items-center">
        <h1 className="p-2 text-lg sm:text-xl md:text-2xl">Documentos</h1>
      </div>
      <div className="bg-gray-100 border-b border-gray-300 drop-shadow-xl rounded-lg p-4 mt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {documentos.map((documento, index) => (
            <div key={index} className="p-4 rounded-lg drop-shadow-xl border-b border-gray-100 bg-white flex flex-col items-center space-y-2">
              <p className="font-bold border-b border-gray-200 mb-2 text-center">{documento.nombreDocumento}</p>
              <img src={documents} alt="exampleDocument" className="w-20 sm:w-24 md:w-28 lg:w-32 mt-4" />
              <p className="text-sm">{documento.fechaSubida}</p>
              <button
                onClick={() => handleDownload(documento.idDocumento)}
                className="w-full drop-shadow-md px-2 py-1 text-xs sm:text-sm hover:bg-gray-100 border-b border-gray-300 hover:text-white hover:bg-gray-800 text-black font-medium rounded transition duration-300"
              >
                Descargar Documento
              </button>
              <button
                onClick={() => handleDelete(documento.idDocumento)}
                className="w-full drop-shadow-md px-2 py-1 text-xs sm:text-sm hover:bg-gray-100 border-b border-gray-300 hover:text-white hover:bg-gray-800 text-black font-medium rounded transition duration-300"
              >
                Eliminar Documento
              </button>
              <button
                onClick={() => openChatModal(documento.idDocumento)}
                className="w-full drop-shadow-md px-2 py-1 text-xs sm:text-sm hover:bg-gray-100 border-b border-gray-300 hover:text-white hover:bg-gray-800 text-black font-medium rounded transition duration-300"
              >
                Chatear con Documento
              </button>
            </div>
          ))}
        </div>
      </div>
      <DocumentChatModal
        isOpen={isModalOpen}
        onClose={closeChatModal}
        documentId={selectedDocumentId}
      />
    </>
  );
};

export default Documentos;
