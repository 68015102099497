import React, { useState, useEffect } from 'react';
import AddSecurityNumberModal from '../modals/AddNumeroSeguridadModal';
import { getIdAbogado } from '../../api/usuarios';
import { decodeToken } from '../../utils/utils';
import Modal from 'react-modal';
import { Loader, Plus } from 'lucide-react';

Modal.setAppElement('#root');

const CasosYNumerosDeSeguridad = () => {
  const [idAbogado, setIdAbogado] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [idRol, setIdRol] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [casos, setCasos] = useState({ casosCreados: 0, casosArchivados: 0 });
  const [numerosSeguridad, setNumerosSeguridad] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const decoded = decodeToken();
      if (decoded && decoded.usuario) {
        const id = await getIdAbogado(decoded.usuario);
        setIdAbogado(id);
        setUsuario(decoded.usuario);
        setIdRol(decoded.idRol);
      }
      await fetchCasos();
      await fetchNumerosSeguridad();
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const fetchCasos = async () => {
    try {
      const response = await fetch('/api/informe-casos');
      const data = await response.json();
      setCasos(data.Details);
    } catch (error) {
      console.error('Error fetching casos:', error);
    }
  };

  const fetchNumerosSeguridad = async () => {
    try {
      const response = await fetch('/api/numeros-seguridad', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if (data.IsSuccessful) {
        setNumerosSeguridad(data.Details);
      } else {
        console.error('Error fetching numeros de seguridad:', data.Description);
      }
    } catch (error) {
      console.error('Error fetching numeros de seguridad:', error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveSecurityNumber = async (securityNumber) => {
    try {
      const response = await fetch('/api/numeros-seguridad', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: localStorage.getItem('token'),
          idRol: idRol,
          idUsuario: idAbogado,
          ...securityNumber
        }),
      });
  
      const data = await response.json();
  
      if (data.IsSuccessful) {
        await fetchNumerosSeguridad();
        handleCloseModal();
        alert(data.Description);
      } else {
        console.error('Error al guardar el número de seguridad:', data.Description);
        alert(`Error: ${data.Description}`);
      }
    } catch (error) {
      console.error('Error al guardar el número de seguridad:', error);
      alert('Error al guardar el número de seguridad. Por favor, intente de nuevo.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-gray-700" size={36} />
      </div>
    );
  }

  return (
    <div className="bg-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-light text-center mb-16 text-gray-900">
          Casos y Números de Seguridad
        </h1>

        <div className="space-y-16">
          {/* Casos creados y archivados */}
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-light text-gray-800">Casos</h2>
            </div>
            <div className="flex justify-between text-center">
              <div className="w-1/2 pr-4">
                <p className="text-4xl font-light text-gray-900 mb-2">{casos.casosCreados}</p>
                <p className="text-sm text-gray-600">Creados</p>
              </div>
              <div className="w-1/2 pl-4 border-l border-gray-200">
                <p className="text-4xl font-light text-gray-900 mb-2">{casos.casosArchivados}</p>
                <p className="text-sm text-gray-600">Archivados</p>
              </div>
            </div>
          </div>
          
          {/* Código de boleta y número de seguridad */}
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-light text-gray-800">Números de Seguridad</h2>
              <button 
                className="text-gray-600 hover:text-gray-900 transition duration-300 flex items-center"
                onClick={handleOpenModal}
              >
                <Plus size={18} className="mr-2" />
                Añadir
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-200">
                    <th className="px-4 py-2 text-left text-sm font-normal text-gray-600">Código de Boleta</th>
                    <th className="px-4 py-2 text-left text-sm font-normal text-gray-600">Número de Seguridad</th>
                    <th className="px-4 py-2 text-left text-sm font-normal text-gray-600">Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {numerosSeguridad.map((num, index) => (
                    <tr key={index} className="border-b border-gray-100">
                      <td className="px-4 py-3 text-sm text-gray-800">{num.codigoBoleta}</td>
                      <td className="px-4 py-3 text-sm text-gray-800">{num.numeroSeguridad}</td>
                      <td className="px-4 py-3 text-sm text-gray-800">{num.tipo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {numerosSeguridad.length === 0 && (
              <p className="text-center text-gray-500 mt-4">No hay números de seguridad registrados.</p>
            )}
          </div>
        </div>
      </div>

      <AddSecurityNumberModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        handleSaveChanges={handleSaveSecurityNumber}
      />
    </div>
  );
};

export default CasosYNumerosDeSeguridad;