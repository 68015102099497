import React from 'react';

const Facturas = () => {

      return(
            <div className = "w-full h-svh flex items-center justify-center">
                  <iframe src='https://facturae.facelcr.com/' title='Facel' className= "w-full h-full"></iframe>
            </div>
      );
};

export default Facturas; 