import React, { useState, useEffect } from 'react';
import EditClientModal from '../components/modals/EditClientModal';

const Cuenta = () => {
  const [usuario, setUsuario] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editableUsuario, setEditableUsuario] = useState({});
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');

  const getTokenInfo = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        return decodedToken;
      } catch (e) {
        console.error('Error decoding token:', e);
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchUsuario = async () => {
      const decodedToken = getTokenInfo();
      if (!decodedToken) {
        setError('Token no válido');
        return;
      }
      const correo = decodedToken.usuario;

      try {
        const response = await fetch(`https://marianachaconramirez.com/api/usuario?correo=${correo}`);
        if (!response.ok) {
          throw new Error('Error al obtener la información del usuario');
        }
        const data = await response.json();
        setUsuario(data);
      } catch (error) {
        console.error('Error fetching usuario:', error);
        setError('Error al cargar la información del usuario');
      }
    };

    fetchUsuario();
  }, []);

  const handleEditClick = () => {
    setEditableUsuario({ ...usuario, password: '' });
    setIsEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableUsuario({ ...editableUsuario, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      const updateUserResponse = await fetch(`https://marianachaconramirez.com/api/usuarios/${usuario.idUsuario}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          correo: editableUsuario.correo,
          password: editableUsuario.password,
          cedula: editableUsuario.cedula,
          nombre: editableUsuario.nombre,
          apellidos: editableUsuario.apellidos,
          telefono: editableUsuario.telefono,
        }),
      });

      const responseText = await updateUserResponse.text();
      console.log('Server response:', responseText);

      if (!updateUserResponse.ok) {
        throw new Error(`Error al actualizar la información del usuario. Estado: ${updateUserResponse.status}`);
      }
      setUsuario({
        ...usuario,
        correo: editableUsuario.correo,
        cedula: editableUsuario.cedula,
        nombre: editableUsuario.nombre,
        apellidos: editableUsuario.apellidos,
        telefono: editableUsuario.telefono,
      });

      setIsEditModalOpen(false);
      setMensaje('Información actualizada correctamente.');
      setError('');
    } catch (error) {
      console.error('Error updating usuario:', error);
      setError(error.message || 'Error al actualizar la información. Intente de nuevo más tarde.');
    }
  };


  return (
    <div className="min-h-screen flex flex-col font-inter mx-20">
      <section className="bg-gray-200 p-4 flex justify-between items-center rounded-md mt-4">
        <div className="text-xl font-semibold">Mi Perfil</div>
      </section>
      <div className="flex-1 p-4 overflow-y-auto">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="bg-gray-800 p-4 flex justify-between items-center rounded-md text-white">
          <div className="text-xl font-semibold">Mi información</div>
        </div>
        <div className="bg-gray-100 rounded-md drop-shadow-xl border-b border-gray-300 p-4 mt-2">
          <div className="flex flex-col md:flex-row items-center mb-4">
            <div className="bg-gray-500 w-24 h-24 rounded-full flex justify-center items-center mb-4 md:mb-0">
              <span role="img" aria-label="user" className="text-white text-4xl">👤</span>
            </div>
            <div className="md:ml-4 text-center md:text-left">
              <div className="text-xl">{usuario.nombre} {usuario.apellidos}</div>
              <div className="flex justify-center md:justify-start space-x-4 mt-2">
                <button className="flex items-center space-x-1" onClick={handleEditClick}>
                  <span role="img" aria-label="edit">✏️</span>
                  <span>Editar Información</span>
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 md:p-8 mt-6 rounded-lg shadow-lg border-b border-gray-300">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b border-gray-200 pb-3">Información General</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <InfoItem label="ID" value={usuario.idUsuario} />
              <InfoItem label="Nombre" value={usuario.nombre} />
              <InfoItem label="Apellidos" value={usuario.apellidos} />
              <InfoItem label="Cédula" value={usuario.cedula} />
              <InfoItem label="Correo Electrónico" value={usuario.correo} />
              <InfoItem label="# Teléfono" value={usuario.telefono} />
            </div>
          </div>
        </div>
      </div>

      <EditClientModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        client={editableUsuario}
        handleInputChange={handleInputChange}
        handleSaveChanges={handleSaveChanges}
      />

      {mensaje && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow">
          {mensaje}
        </div>
      )}
    </div>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="group">
    <div className="text-sm font-medium text-gray-500 mb-1 transition-colors group-hover:text-indigo-600">{label}</div>
    <div className="text-base text-gray-800 font-semibold transition-colors group-hover:text-indigo-800">{value}</div>
  </div>
);

export default Cuenta;