import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDown, ChevronUp, MessageCircle } from 'lucide-react';

const formatPrice = (price) => {
    return `₡${price.toLocaleString('es-CR')}`;
};

const API_URL = 'https://marianachaconramirez.com';
const whatsappNumber = '50688888888';
const whatsappMessage = 'Hola, me gustaría agendar una consulta.';

const createWhatsAppLink = () => {
    return `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
};

const NuestrosServicios = () => {
    const [services, setServices] = useState([]);
    const [openService, setOpenService] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.post(`${API_URL}/obtenerServicios`, { token });
                if (response.data.IsSuccessful) {
                    setServices(response.data.Details.map(service => ({
                        id: service.idServicio,
                        name: service.nombreServicio,
                        description: service.descripcion,
                        basePrice: service.precioBase
                    })));
                } else {
                    console.error('Error fetching services:', response.data.Description);
                }
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };
        fetchServices();
    }, [token]);

    return (
        <div className="bg-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-light text-center mb-2 text-gray-900">
                    Nuestros Servicios más Solicitados
                </h1>
                <p className="text-center text-lg mb-16 text-gray-500">
                    Soluciones legales de calidad para sus necesidades
                </p>

                <div className="space-y-8">
                    {services.map(service => (
                        <div key={service.id} className="border-b border-gray-200 pb-4">
                            <div
                                className="flex justify-between items-center cursor-pointer"
                                onClick={() => setOpenService(openService === service.id ? null : service.id)}
                            >
                                <h3 className="text-xl font-normal text-gray-800">{service.name}</h3>
                                {openService === service.id ?
                                    <ChevronUp className="text-gray-400" size={20} /> :
                                    <ChevronDown className="text-gray-400" size={20} />
                                }
                            </div>
                            <div className={`mt-2 transition-all duration-300 ease-in-out ${openService === service.id ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                                <p className="text-gray-600 mb-2">{service.description}</p>
                                <p className="text-xl font-light text-gray-900">
                                    {formatPrice(service.basePrice)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-16 text-center">
                    <p className="text-gray-600 mb-6">
                        Para consultas específicas o información detallada, estamos a su disposición.
                    </p>
                    <a
                        href={createWhatsAppLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center justify-center bg-gray-900 hover:bg-gray-800 text-white font-light py-2 px-6 rounded-full transition duration-300"
                    >
                        <MessageCircle className="w-5 h-5 mr-2" />
                        Contactar por WhatsApp
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NuestrosServicios;
